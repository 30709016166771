import React from 'react'
import { CenteredResponsiveContainer, Layout } from '@/components/Layout'
import { SEO } from '@/components/SEO'
import tw from 'twin.macro'

const Section = tw.div`mb-16`
const List = tw.ul`list-disc`
const ListItem = tw.li`ml-6`
const Paragraph = tw.div`my-3`
const Anchor = tw.a`text-jump-red`
const Bold = tw.span`font-semibold`

export default function Communication() {
  return (
    <Layout>
      <SEO title="Communication" />
      <section>
        <CenteredResponsiveContainer>
          <Section>
            <Paragraph>
              Beware of scams impersonating Jump. We do not have retail customers or otherwise manage
              investments for third parties. Anyone using our name to solicit or accept investments
              from you is trying to defraud you. Unfortunately, Jump Trading Group is aware of
              sophisticated scammers attempting to carry out this activity, using tactics including
              the creation of profiles on chat and social media applications and the use of fake
              websites and mobile applications.
            </Paragraph>
            <Paragraph>
              Under no circumstances should any member of the public transfer cryptocurrency or any
              funds to any wallet address on the basis of a purported link to a Jump Trading Group
              entity.
            </Paragraph>
            <Paragraph>
              If you would like to share information regarding fraudulent activity related to Jump
              Trading Group, please reach out to&nbsp;
              <Anchor href="mailto:BrandProtection@jumptrading.com">
                BrandProtection@jumptrading.com
              </Anchor>
              . If you believe you are a victim of fraud, please contact local law enforcement.
            </Paragraph>

            <Paragraph>
              Below are the official websites and social media accounts operated by Jump Trading
              Group.
            </Paragraph>

            <Bold>Websites:</Bold>
            <Paragraph>
              <List>
                <ListItem>https://www.jumptrading.com/</ListItem>
                <ListItem>https://jumpcrypto.com/</ListItem>
                <ListItem>https://jumpcap.com/</ListItem>
                <ListItem>https://www.jumpliquidity.com/</ListItem>
              </List>
            </Paragraph>

            <Bold>LinkedIn:</Bold>
            <Paragraph>
              <List>
                <ListItem>https://www.linkedin.com/company/jump-trading</ListItem>
                <ListItem>https://www.linkedin.com/company/jump-crypto</ListItem>
                <ListItem>https://www.linkedin.com/company/jump-capital</ListItem>
              </List>
            </Paragraph>

            <Bold>Twitter/X:</Bold>
            <Paragraph>
              <List>
                <ListItem>@jumptrading</ListItem>
                <ListItem>@jump_</ListItem>
                <ListItem>@JumpEngineering</ListItem>
                <ListItem>@jumpcapital</ListItem>
              </List>
            </Paragraph>
            
            <Paragraph>Jump Trading Group does not have any mobile applications, or official channels on apps including Telegram or Whatsapp.</Paragraph>
          </Section>
        </CenteredResponsiveContainer>
      </section>
    </Layout>
  )
}
